.main-layout-sec {
  height: calc(100vh - 94px);

  #sidebar {
    height: calc(100vh - 94px);

    .sidebar-menu-list {
      margin-top: 10px !important;

      ul {
        li {
          margin-bottom: 35px;

          &:last-child {
            margin-bottom: 0;
          }

          a {}
        }
      }
    }
  }
}


@media only screen and (max-width: 1024px) {

  .main-sidebar-sections {
    position: absolute;

    #sidebar {
      transition: 0.5s all;
      .sidebar-menu-list {
        margin-top: 0 !important;

        ul {
          li {
            margin-bottom: 22px;

            a {}
          }
        }
      }

      .toggle-btn-sec {
        background: #c00000;
        height: 40px;
        width: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-left: 16px;
        position: absolute;
        right: -40px;
        top: 50px;
        
      }
    }

  }

  .sidebar-expanded {
    .main-sidebar-sections {
      position: absolute;

      #sidebar {
        position: absolute;
        left: -15rem;
        

        .sidebar-menu-list {
          margin-top: 0 !important;

          ul {
            li {
              margin-bottom: 22px;

              a {}
            }
          }
        }
      }


    }
  }

  .main-layout-sec {
    height: calc(100vh - 22px);

    #sidebar {
      height: calc(100vh - 22px);
      position: relative;
      &::after{

      }
    }
  }

}

.input-w-70{
  min-width: 70% !important;
}